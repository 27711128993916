import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Socials from 'components/utils/Socials/Socials'
import ShowMoreText from 'components/utils/ShowMore/ShowMoreText'

function CompanyDetails({ company }) {
  const {
    aka,
    street,
    city,
    state,
    zip,
    country,
    phone,
    web_domain,
    web_contact,
    email,
    employee_count,
    stock_symbol,
    stock_exchange,
    assoc_companies,
    assoc_brands,
    logo_id,
  } = company

  const addr =
    street &&
    city &&
    state &&
    zip &&
    country &&
    `${street}, ${city}, ${state}, ${zip} ${country}`

  return (
    <ul className='text-sm'>
      {addr && (
        <li>
          <span className='text-secondaryGrey'>Address:&nbsp;</span>

          <a target='_blank' href={`https://maps.google.com/?q=${addr}`}>
            {addr}
          </a>
        </li>
      )}
      {phone && (
        <li>
          <span className='text-secondaryGrey'>Phone:&nbsp;</span>

          <a target='_blank' href={`tel:${phone}`}>
            {phone}
          </a>
        </li>
      )}
      {web_domain && (
        <li>
          <span className='text-secondaryGrey'>Website:&nbsp;</span>

          <a target='_blank' href={web_contact}>
            {logo_id}
          </a>
        </li>
      )}
      {email && (
        <li>
          <span className='text-secondaryGrey'>Contact Email:</span>{' '}
          <a href={`mailto:${email}`}>{email}</a>
        </li>
      )}
      {employee_count && (
        <li>
          <span className='text-secondaryGrey'>Number of Employees (Approximate):</span>{' '}
          {employee_count}
        </li>
      )}
      {stock_symbol && (
        <li>
          <span className='text-secondaryGrey'>Stock Symbol:</span> {stock_symbol}
        </li>
      )}
      {stock_exchange && (
        <li>
          <span className='text-secondaryGrey'>Stock Exchange:</span> {stock_exchange}
        </li>
      )}
      <li>
        <span className='text-secondaryGrey'>Social Media Accounts:</span>{' '}
        <Socials source={company} />
      </li>
      {assoc_companies && (
        <li>
          <span className='text-secondaryGrey'>Associated Companies:</span>{' '}
          <ShowMoreText text={assoc_companies} length={20} />
        </li>
      )}
      {assoc_brands && (
        <li>
          <span className='text-secondaryGrey'>Associated Brands:</span>
          <ShowMoreText text={assoc_brands} length={20} />
        </li>
      )}
    </ul>
  )
}

export default CompanyDetails

CompanyDetails.propTypes = {
  company: PropTypes.object,
}
