import './App.css'
import RoutePaths from './route/RoutePaths'
import Footer from 'components/Footer/Footer'
import Header from 'components/Header/Header'
import IndexContext from 'components/utils/IndexContext/IndexContext'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  useGetSearchIndexQuery,
  useGetAllListQuery,
  useGetBlurbsQuery,
} from 'services/Database'
import lunr from 'lunr'

console.log('REACT_APP_CF_ENV', process.env.REACT_APP_CF_ENV)

const App = () => {
  const { pathname } = useLocation()
  const [index, setIndex] = useState(null)
  const {
    data: indexData,
    error: indexError,
    isLoading: indexLoading,
  } = useGetSearchIndexQuery()

  // we don't actually use the data here, but other reducers match on its results
  const { data, error, isLoading } = useGetAllListQuery()
  useGetBlurbsQuery()

  useEffect(() => {
    if (indexData) {
      const index = lunr.Index.load(indexData)
      setIndex(index)
    }
  }, [indexData])

  return (
    <IndexContext.Provider value={index}>
      <div className='App'>
        <div className='container grow h-full my-0 mx-auto flex flex-col'>
          {pathname !== '/' && pathname !== '/search' ? <Header /> : null}
          <RoutePaths />
        </div>
        <div className='footer'>
          <Footer />
        </div>
      </div>
    </IndexContext.Provider>
  )
}

export default App
