import { createSlice } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit'
import kvApi from '../../services/Database';

export const blurbsAdapter = createEntityAdapter({
    // selectId: (rec) => rec.id,
    // sortComparer: (a, b) => a.name.localeCompare(b.name),
})

const blurbsSlice = createSlice({
    name: 'blurbs',
    initialState: blurbsAdapter.getInitialState(),
    reducers: {
        addOne: blurbsAdapter.addOne,
        setAll: blurbsAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder.addMatcher(kvApi.endpoints.getBlurbs.matchFulfilled, (state, { payload }) => {
            console.log("getAllBlurbs matcher", payload[0])
            blurbsAdapter.setAll(state, payload)
            return state
        })
    }
})

export const {
    addOne,
    setAll
} = blurbsSlice.actions
export const { selectAll, selectEntities } = blurbsAdapter.getSelectors(state => state.blurbs)
export default blurbsSlice.reducer;

