import {createSlice} from '@reduxjs/toolkit';
import{ createEntityAdapter } from '@reduxjs/toolkit'
import kvApi from '../../services/Database';

export const recsAdapter = createEntityAdapter({
    selectId: (rec) => rec.__id,
    // sortComparer: (a, b) => a.name.localeCompare(b.name),
  })

const recsSlice = createSlice({
    name: 'recs',
    initialState: recsAdapter.getInitialState(),
    reducers: {
      addOne: recsAdapter.addOne,
      setAll: recsAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder.addMatcher(kvApi.endpoints.getAllList.matchFulfilled,(state,{payload}) => {
            console.log("Records getAllList matcher", payload[0])
            recsAdapter.setAll(state,payload)
            return state
            })
      }
  })

  export const {
    addOne,
    setAll
  } = recsSlice.actions
  export const { selectAll, selectEntities } = recsAdapter.getSelectors(state => state.recs)
  export default recsSlice.reducer;

