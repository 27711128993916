import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define a service using a base URL and expected endpoints
export const kvApi = createApi({
  reducerPath: 'kvAPI',
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.REACT_APP_CF_ENV === 'production'
        ? 'https://unfk.io/kv/'
        : 'https://staging.unfk.pages.dev/kv/',
  }),
  tagTypes: ['rec'],
  endpoints: builder => ({
    getDetailByID: builder.query({
      query: id => id,
    }),
    getAllCompanies: builder.query({
      query: () => 'orgs',
    }),
    getAllPoliticians: builder.query({
      query: () => 'pols',
    }),
    getDetailsByIDs: builder.query({
      query: ids => `details?ids=${ids}`,
    }),
    getSearchIndex: builder.query({
      query: () => 'index',
    }),
    getAllList: builder.query({
      query: () => 'all',
    }),
    getWorst10: builder.query({
      query: () => 'orgsWorst10',
    }),
    getBlurbs: builder.query({
      query: () => 'blurbs',
    }),
  }),
})

export const { useGetDetailByIDQuery, useGetAllCompaniesQuery, useGetAllPoliticiansQuery, useGetDetailsByIDsQuery, useGetSearchIndexQuery, useGetAllListQuery, useGetWorst10Query, useGetBlurbsQuery } = kvApi
export default kvApi
