import {createSlice} from '@reduxjs/toolkit';
import kvApi from '../../services/Database';

const company = createSlice({
  name: 'company',
  initialState: {
    database: null,
    topWorst: null,
    currentSelected: null,
    companyProfile: null
  },
  reducers: {
    setCompanies: (state, action) => {
      state.database = action.payload;
      state.topWorst = action.payload.slice(0,10)
    },
    updateTopWorst: (state, action) => {
      //state.topWorst = action.payload;
    },
    getProfile: (state, action) => {
      state.currentSelected = action.payload;
    },
    getCompanyProfile: (state, action) => {
      state.companyProfile = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(kvApi.endpoints.getAllList.matchFulfilled,(state,{payload}) => {
      const orgs = payload.filter(rec => rec.type === 'org')
      console.log("Companies getAllList matcher", orgs[0])
      state.database = orgs;
      state.topWorst = orgs.slice(0,10)
      return state
    })
  }
});

export default company.reducer;

export const {setCompanies, updateTopWorst, getProfile, getCompanyProfile} = company.actions;
