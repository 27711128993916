import { configureStore } from '@reduxjs/toolkit';
import companyReducer from '../reducers/Company';
import politicianReducer from '../reducers/Politican';
import recordsReducer from '../reducers/Records';
import blurbsReducer from '../reducers/Blurb';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { kvApi } from '../../services/Database';
const store = configureStore({
  reducer: {
    company: companyReducer,
    politician: politicianReducer,
    recs: recordsReducer,
    blurbs: blurbsReducer,
    [kvApi.reducerPath]: kvApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(kvApi.middleware),
});

setupListeners(store.dispatch)

export default store