export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});
export const usdFormat = (amount) => usdFormatter.format(amount);
export const scoreFormat = (score) => Math.round((score * 1000) / 10) + "%";
export const lcvFormat = (score) => score + "%";
export const photoURL = (photo_id) =>
  (photo_id &&
    `https://imagedelivery.net/T0emcPD7R7NKDpCktTu5_g/${photo_id}/public`) ||
  "";
export const polTitle = (party, chamber, state) => {
  const title = chamber.includes('House')
    ? 'Representative'
    : chamber.includes('Senate')
      ? 'Senator'
      : ''
  return `${party} ${title} from ${state}`
}
export const getTokens = (field, metadata) => {
  let tokens = []
  for (const [k, v] of Object.entries(metadata)) {
    if (v[field]) tokens.push(k)
  }
  return tokens
}

export const getBgColor = score => {
  if (score <= 0.4) {
    return 'bg-redScore'
  } else if (score <= 0.7) {
    return 'bg-orangeScore'
  } else if (score <= 0.99) {
    return 'bg-yellowScore'
  } else {
    return 'bg-greenScore'
  }
}

export const unfkCard = () => 'text-primaryBlack py-[2px] px-[4px] font-bold text-xl rounded-sm w-fit min-w-[50px] text-center'

export const pick = (obj, keys) =>
  Object.fromEntries(keys.filter(key => key in obj).map(key => [key, obj[key]]))

export const isString = (str) => {
  return str && str.trim() != ''
}
