import {createSlice} from '@reduxjs/toolkit';
import kvApi from '../../services/Database';

const politician = createSlice({
  name: 'politician',
  initialState: {
    politicanList: null,
    
  },
  reducers: {
    setPoliticians: (state, action) => {
      state.politicanList = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addMatcher(kvApi.endpoints.getAllList.matchFulfilled,(state,{payload}) => {
  //     const pols = payload.filter(rec => rec.type === 'pol')
  //     console.log("Politicians getAllList matcher", pols[0])
  //     state.politicanList = pols;
  //     return state
  //   })
  // }
});

export default politician.reducer;

export const {setPoliticians} = politician.actions;
